






























import { PropType } from 'vue';
import {
  TStreamerStatActionsBlock,
  TStreamerStatTransactionBlock
} from '@/views/CopyStake/CopyStakeStats/CopyStakeStats.vue';

export default {
  name: 'CopyStakeStatsCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    amount: {
      type: String,
      default: '-'
    },
    color: {
      type: String,
      default: '#FFCC00'
    },
    transactionsBlock: {
      type: Object as PropType<TStreamerStatTransactionBlock>,
      default: undefined
    },
    actionsBlock: {
      type: Object as PropType<TStreamerStatActionsBlock>,
      default: undefined
    }
  }
};
