export function doDownload(objectURL: string, name: string): void {
  const link = document.createElement('a');
  link.download = name;
  link.href = objectURL;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default async function download(
  uri: string,
  name: string
): Promise<void> {
  if (!uri || !name) {
    return;
  }

  if (uri.startsWith('data:')) {
    return doDownload(uri, name);
  }

  return fetch(uri)
    .then((response) => response.blob())
    .then((blob) => doDownload(URL.createObjectURL(blob), name))
    .catch(console.error);
}
