





















import { PropType } from 'vue';

export default {
  name: 'Tooltip',
  props: {
    icon: {
      type: String as PropType<string>,
      default: (): string => 'mdi-alert-circle-outline'
    },
    text: {
      type: String as PropType<string>,
      default: (): string => ''
    },
    html: {
      type: String as PropType<string>,
      default: (): string => ''
    },
    maxWidth: {
      type: Number as PropType<number>,
      default: (): number => 370
    },
    alternativeView: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    smallIcon: {
      type: Boolean,
      default: (): boolean => true
    },
    sizeIcon: {
      type: Number,
      default: (): number | null => null
    }
  }
};
