
































import { PropType } from 'vue';
import StatsCard from '@/components/StatsCard.vue';
import CopyStakeStatCard from '@/views/CopyStake/CopyStakeStats/CopyStakeStatsCard.vue';
import CopyStakeStatsStreamersModal from '@/views/CopyStake/CopyStakeStats/CopyStakeStatsStreamersModal.vue';
import {
  DateRangeParams,
  EStreamerTransactionType,
  TStreamerStat
} from '@/api/schema';
import {
  VALUE_PLACEHOLDER,
  BASE_CURRENCY,
  STATS_NAME
} from '@/helpers/copyStakeHelpers';

export type TStreamerStatTransactionBlock = {
  title?: string;
  count: string;
};
export type TStreamerStatActionsBlock = {
  icon: string;
  clickHandler: () => void;
  tooltip?: string;
};

type TStreamerStatFormatted = {
  title: string;
  amount: string;
  color: string;
  transactionsBlock?: TStreamerStatTransactionBlock;
  actionsBlock?: TStreamerStatActionsBlock;
};

const STATS_ORDER = [
  EStreamerTransactionType.BET_COPIED,
  EStreamerTransactionType.WIN_COPIED,
  EStreamerTransactionType.ROLLBACK_BET_COPIED,
  EStreamerTransactionType.ROLLBACK_WIN_COPIED
];
const STATS_COLOR_MAP = {
  [EStreamerTransactionType.BET_COPIED]: '#FFCC00',
  [EStreamerTransactionType.WIN_COPIED]: '#6FCF97',
  [EStreamerTransactionType.ROLLBACK_BET_COPIED]: '#ED9586',
  [EStreamerTransactionType.ROLLBACK_WIN_COPIED]: '#32BAFF',
  UNIQUE_STREAMERS: '#6F90CF'
};

const getDefaultStatsData = (): TStreamerStatFormatted[] =>
  STATS_ORDER.map((type) => ({
    title: STATS_NAME[type],
    amount: VALUE_PLACEHOLDER,
    color: STATS_COLOR_MAP[type],
    transactionsBlock: {
      count: VALUE_PLACEHOLDER
    }
  }));

export default {
  name: 'CopyStakeStats',
  components: {
    CopyStakeStatsStreamersModal,
    CopyStakeStatCard,
    StatsCard
  },
  data: (): any => ({
    openStreamersModal: false
  }),
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    ggr: {
      type: Number,
      default: 0
    },
    streamersCount: {
      type: [Number, String],
      default: 0
    },
    stats: {
      type: Array as PropType<TStreamerStat[]>,
      default: []
    },
    blocked: {
      type: Boolean,
      default: false
    },
    dates: {
      type: Object as PropType<DateRangeParams>,
      default: (): DateRangeParams => {
        return {} as DateRangeParams;
      }
    }
  },
  computed: {
    formattedGGR(): string {
      return `${this.$options.filters.numeralSpaces(
        this.ggr || 0,
        '0,0.[00]'
      )} USD`;
    },
    formattedStats(): TStreamerStatFormatted[] {
      const data = getDefaultStatsData();

      this.stats.forEach((item: TStreamerStat) => {
        data[STATS_ORDER.indexOf(item.copiedActionType)] = {
          title: STATS_NAME[item.copiedActionType],
          amount: this.formatValue(item.copiedActionAmountUsd, BASE_CURRENCY),
          color: STATS_COLOR_MAP[item.copiedActionType],
          transactionsBlock: {
            count: this.formatValue(item.copiedActionCount)
          }
        };
      });

      data.push({
        title: 'Unique Streamers',
        amount: this.streamersCount.toString(),
        color: STATS_COLOR_MAP.UNIQUE_STREAMERS,
        actionsBlock: {
          icon: 'mdi-eye',
          clickHandler: () => {
            this.openStreamersModal = true;
          }
        }
      });

      return data;
    }
  },
  methods: {
    formatValue(v: string | number, currency: string = ''): string {
      if (v === VALUE_PLACEHOLDER || (!v && typeof v !== 'number'))
        return VALUE_PLACEHOLDER;

      try {
        return `${this.$options.filters.numeralSpaces(
          v,
          '0,0.[00]'
        )} ${currency}`.trim();
      } catch {
        return VALUE_PLACEHOLDER;
      }
    }
  }
};
