
















































import { STATS_NAME } from '@/helpers/copyStakeHelpers';
import { EStreamerTransactionType } from '@/api/schema';

type TCopyStakeTypeFilterState = {
  isFilterMenuOpened: boolean;
  activeType: EStreamerTransactionType;
  filterTypes: Readonly<{ text: string; value: string }[]>;
};

export default {
  name: 'CopyStateTransactionTypeFilter',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selectedType: {
      type: String,
      default: null
    }
  },
  data(): TCopyStakeTypeFilterState {
    return {
      activeType: null,
      isFilterMenuOpened: false,
      filterTypes: Object.freeze(
        Object.entries(STATS_NAME).map(([value, text]) => ({
          text,
          value: value.replaceAll('_COPIED', '')
        }))
      )
    };
  },
  methods: {
    handlerOnFilterApply(status: boolean): void {
      if (status === false) {
        this.activeType = null;
      }

      this.isFilterMenuOpened = false;
      this.$emit('change', this.activeType);
    }
  }
};
