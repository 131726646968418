



















import Tooltip from '@/components/tooltip/Tooltip.vue';

export default {
  name: 'StatsCard',
  components: {
    Tooltip
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    additionalValue: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: 'Total amount'
    },
    icon: {
      type: String,
      default: 'mdi-cash-fast'
    },
    alternative: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    color(): string {
      return this.alternative ? '#9155FD' : '#12b76a';
    },
    bgColor(): string {
      return this.alternative ? '#FFFFFF' : '#eaf5ea';
    }
  }
};
