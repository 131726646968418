import { DateTime } from 'luxon';

export default function formatDate(
  date: string,
  formatPattern: string,
  isISO: boolean = false,
  isLocalTime: boolean = true
): string {
  const d = isISO
    ? DateTime.fromISO(date, { zone: 'UTC' })
    : DateTime.fromSQL(date, { zone: 'UTC' });

  return (isLocalTime ? d.toLocal() : d).toFormat(formatPattern);
}
