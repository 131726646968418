












































import { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { AxiosError } from 'axios';
import BaseDialog from '@/components/BaseDialog.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import {
  getCopyBetStatsStreamer,
  getCopyBetStatsStreamerCsv
} from '@/api/CopyStake';
import { errorToastMessage } from '@/helpers/errorToastMessage';

import { doDownload } from '@/helpers/download';
import { DateRangeParams } from '@/api/schema';

const defaultOptions = {
  page: 1,
  itemsPerPage: 5
};

export default {
  name: 'CopyStakeStatsStreamersModal',
  components: {
    Tooltip,
    BaseDialog
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    dates: {
      type: Object as PropType<DateRangeParams>,
      default: (): DateRangeParams => {
        return {} as DateRangeParams;
      }
    }
  },
  data(): any {
    return {
      list: [],
      total: null,
      loading: {
        list: false,
        csv: false
      },
      headers: [
        { text: 'Streamer Wallet Address', value: 'walletHash' },
        { text: 'Nickname', value: 'nickname' },
        { text: 'Followers', value: 'uniqueUserCount' },
        { text: 'Users with FTD', value: 'ftdUserCount' }
      ],
      options: { ...defaultOptions }
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId'])
  },
  methods: {
    getData(): void {
      if (!this.operatorId) return;

      this.loading.list = true;

      getCopyBetStatsStreamer({
        ...this.dates,
        size: this.options.itemsPerPage,
        page: this.options.page - 1
      })
        .then((data) => {
          this.list = data.content;
          this.total = data.totalElements;
        })
        .catch((err: AxiosError) => {
          errorToastMessage(err);
        })
        .finally(() => {
          this.loading.list = false;
        });
    },
    async handlerOnDownloadCSV(): Promise<void> {
      try {
        this.loading.csv = true;

        const data = await getCopyBetStatsStreamerCsv({
          ...this.dates
        });

        const url = window.URL.createObjectURL(new Blob([data]));
        const date = Object.values(this.dates)
          .map((item: string) => item.replaceAll('-', '.'))
          .join('-');

        doDownload(url, `copy-stake-stats-streamers[${date}].csv`);
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.loading.csv = false;
      }
    },
    resetData(): void {
      this.list = [];
      this.total = null;
      this.loading.list = false;
      this.loading.csv = false;
      this.options = { ...defaultOptions };
    },
    closeModal(): void {
      this.resetData();
      this.$emit('input', false);
    }
  }
};
