

























import type { PropType } from 'vue';

export default {
  name: 'BaseDialog',
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    loading: {
      type: Boolean,
      default: (): boolean => false
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    closeDialog(): void {
      this.$emit('close');
      this.$emit('input', false);
    }
  }
};
